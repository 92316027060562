.article {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
}

.article h1 {
  margin-top: 0;
}

.article p {
  margin-bottom: 0;
  text-align: justify;
}

.article a {
  color: rgb(39, 0, 180);
  text-decoration: none;
}

.article a:hover,
.article a:active {
  color: rgb(255, 255, 255);
  transition: all 200ms ease-in-out;
}

@media (prefers-color-scheme: dark) {
  .article h1 {
    color: rgb(0, 0, 0);
  }
  .article p {
    color: rgb(0, 0, 0);
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  .article {
    max-height: unset;
  }
}

@media (min-width: 1025px) {
  .article {
    padding-left: 1.5rem;
    max-height: 75vh;
  }
}
