.galleryBackdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.galleryModal {
  position: static;
  width: 100%;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  animation: pop-out 300ms ease-out forwards;
  left: unset;
}

.galleryModal > div {
  width: 100%;
}

.galleryWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0 auto;
}

.singleImage {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  overflow: visible;
}

.singleImage img {
  width: 100%;
  max-width: 100%;
}

.fullscreenImage {
  width: 100%;
}

.fullscreenImage img {
  max-width: 100%;
  max-height: 100%;
}

.img-loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.img-loaded {
  filter: blur(0px);
  transition: filter 500 linear;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  z-index: 20;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

@media (min-width: 902px) {
  .singleImage {
    border: 3px solid rgba(255, 255, 255, 0.5);
    flex-basis: content;
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  .galleryModal {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .galleryModal > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .singleImage {
    width: 99px;
  }

  .fullscreenImage {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    order: 2;
  }

  .next,
  .prev {
    position: static;
    border-radius: 50%;
    padding: 1.3rem;
    width: 20px;
    height: 20px;
    font-size: 30px;
  }

  .next {
    margin-left: 1.5rem;
    order: 3;
  }

  .prev {
    margin-right: 1.5rem;
    transform: scale(-1);
    order: 1;
  }
}

@media (min-width: 1025px) {
  .galleryBackdrop {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .galleryModal {
    width: 70%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pop-out 300ms ease-out forwards;
  }

  .galleryModal > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .galleryWrapper {
    max-width: 100%;
    max-height: 100%;
    overflow: visible;
  }

  .singleImage {
    width: 300px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    flex-basis: unset;
    margin: 0.5rem 0;
  }

  .singleImage img {
    transition: transform 300ms ease-out;
  }

  .singleImage img:hover {
    transform: scale(1.06);
  }

  .fullscreenImage {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    order: 2;
    cursor: grab;
  }

  .fullscreenImage img {
    max-width: 100%;
    max-height: 100%;
  }

  .next,
  .prev {
    position: static;
    border-radius: 50%;
    padding: 1.5rem;
    width: 50px;
    height: 50px;
    font-size: 30px;
  }

  .next {
    margin-left: 1.5rem;
    order: 3;
  }

  .prev {
    margin-right: 1.5rem;
    transform: scale(-1);
    order: 1;
  }
}

@keyframes pop-out {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
