.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 4.7rem);
}

@media (min-width: 1025px) {
  .main {
    display: block;
    width: unset;
    height: unset;
  }
}
