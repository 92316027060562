.section h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  .section h3 {
    color: rgb(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  .section {
    width: 50%;
    padding: 0 1rem 0 0.5rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
    overflow: auto;
  }
}
