.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1.5rem;
  background-color: rgba(179, 179, 179, 0.5);
  backdrop-filter: blur(5px);
  overflow: auto;
}

.card > div {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.card > div::-webkit-scrollbar {
  display: none;
}

@media (min-width: 415px) {
  .card {
    width: 23.69rem;
    left: calc(50% - 11.85rem);
  }
}

@media (min-width: 902px) {
  .card {
    width: 45.15rem;
    left: calc(50% - 22.58rem);
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  .card {
    top: 30vh;
  }
}

@media (min-width: 1025px) {
  .card {
    position: absolute;
    top: 17vh;
    width: 62rem;
    left: calc(50% - 31rem);
    height: fit-content;
    max-height: 35rem;
  }

  .card > div {
    height: unset;
  }
}
