.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.error-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  color: #b40e0e;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 1025px) and (orientation: landscape) {
  .list {
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media (min-width: 1025px) {
  .list {
    flex-direction: column;
    padding: 0 1.5rem;
    width: 17rem;
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
