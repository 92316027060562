.card {
  margin: 0;
  padding: 1rem;
  height: fit-content;
  width: 100%;
}

.card > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (prefers-color-scheme: dark) {
  .card {
    background-color: rgba(179, 179, 179, 0.92);
  }
}

@media (min-width: 1025px) {
  .card {
    display: flex;
    position: absolute;
    top: 20vh;
    width: fit-content;
    left: calc(50% - 27.28rem);
    max-height: 75vh;
    padding: 1rem 1rem 1rem 2rem;
  }

  .card > div {
    padding-right: 1rem;
  }
}
