.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.infoListCard {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  height: minmax(fit-content, calc(100% - 6.75rem));
}

.infoListCard > div {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.singleInfoCard {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  max-height: calc(100% - 6.75rem);
}

.singleInfoCard > div {
  display: flex;
  width: 100%;
  height: 100%;
}

@media (max-width: 1025px) and (orientation: landscape) {
  .wrapper {
    width: 100%;
    flex-direction: row;
    gap: 0.5rem;
  }

  .infoListCard {
    max-height: 100%;
  }

  .infoListCard > div {
    max-height: 100%;
  }

  .singleInfoCard {
    width: 100%;
    max-height: 100%;
  }
}

@media (min-width: 1025px) {
  .wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 20vh;
    left: calc(50% - 32.5rem);
    width: 65rem;
    height: fit-content;
    max-height: 75vh;
  }

  .infoListCard {
    padding-top: 1rem;
    max-width: 17rem;
    height: fit-content;
  }
}
