.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.3rem 0.6rem;
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 10;
}

.toggle:focus {
  outline: none;
}

.toggle span {
  background: rgb(0, 0, 0);
  margin: 0.34rem 0;
  border-radius: 22px;
  width: 100%;
  height: 0.32rem;
}

.main-navigation ul {
  display: none;
}

.main-navigation li {
  margin: 0.5rem;
  display: inline-flex;
}

.main-navigation a,
.btn__logout {
  border-radius: 22px;
  font: inherit;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.main-navigation a {
  box-sizing: border-box;
  padding: 0.7rem 0.9rem;
  text-decoration: none;
  color: black;
  background-color: rgba(218, 218, 218, 0.65);
  border: 2px solid rgba(218, 218, 218, 0);
}

.main-navigation a:hover,
.main-navigation a:active,
.main-navigation a.active {
  background-color: rgba(255, 255, 255, 0.65);
  border: 2px solid rgba(0, 0, 0, 0.7);
  transition: all 0.2s ease-out;
}

.main-navigation > div {
  display: flex;
  align-items: center;
}

.btn__logout {
  margin: 0.4rem;
  padding: 0.83rem 1.03rem;
  border: none;
  background: rgb(7, 0, 120);
  background: linear-gradient(
    90deg,
    rgba(7, 0, 120, 1) 0%,
    rgba(42, 42, 155, 1) 50%,
    rgba(0, 37, 195, 1) 100%
  );
  color: rgb(251, 255, 0);
}

@media (min-width: 1025px) {
  .toggle {
    display: none;
  }

  .main-navigation ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .btn__logout:hover,
  .btn__logout:active {
    background: rgba(251, 255, 0, 0.65);
    color: rgb(0, 0, 0);
    transition: all 0.2s ease-out;
  }
}
