.list-item {
  display: flex;
}

.list-item a {
  border-radius: 22px;
  margin: 0 0.2rem;
  padding: 0.5rem;
  max-width: 9.8rem;
  border: 2px solid rgba(218, 218, 218, 0);
  color: black;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.list-item h2 {
  margin: 0;
  padding: 0;
}

.list-item p {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-item a:hover,
.list-item a:active,
.list-item a.active {
  background-color: rgba(255, 255, 255, 0.65);
  border: 2px solid rgba(0, 0, 0, 0.7);
  transition: all 0.2s ease-out;
}

@media (prefers-color-scheme: dark) {
  .list-item h2 {
    color: rgb(0, 0, 0);
  }
  .list-item p {
    color: rgb(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  .list-item {
    width: 100%;
    height: 100%;
  }

  .list-item a {
    display: block;
    margin: 0.5rem 0;
    height: 100%;
    width: 100%;
    max-width: unset;
    white-space: unset;
  }

  .list-item p {
    font-size: 1.2rem;
  }
}
