.modalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  width: 100%;
  background-color: transparent;
  border-radius: 22px;
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

@media (min-width: 959px) {
  .modal {
    position: static;
    width: 80%;
    padding: 1.5rem;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
