.heading {
  margin: 0;
  text-align: center;
}

.heading ~ p {
  text-align: justify;
}

.heading ~ ul {
  margin: 0;
  padding-left: 1.22rem;
  text-align: left;
}

@media (prefers-color-scheme: dark) {
  .heading {
    color: rgb(0, 0, 0);
  }

  .heading ~ p {
    color: rgb(0, 0, 0);
  }
}
