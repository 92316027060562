.card {
  display: flex;
  height: 100%;
}

.card > div {
  width: 100%;
  overflow: auto;
}

@media (min-width: 1025px) {
  .card {
    position: absolute;
    top: 17vh;
    width: 80%;
    left: 10%;
    max-height: 80vh;
    justify-content: space-between;
  }

  .card > div {
    display: flex;
    justify-content: space-between;
  }
}
