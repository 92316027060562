.section article {
  margin-bottom: 0.5rem;
}

.header-link {
  font-weight: bold;
}

.section h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.section p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.section a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.operator-div {
  margin-bottom: 0.5rem;
}

.phone-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: "100%";
}

.phone-link p {
  margin: 0;
}

.phone-link a {
  color: rgb(41, 41, 41);
  margin-left: 0.2rem;
  vertical-align: middle;
}

@media (prefers-color-scheme: dark) {
  .section h3 {
    color: rgb(0, 0, 0);
  }
  .section p {
    color: rgb(0, 0, 0);
  }
  .section a {
    color: rgb(0, 0, 0);
  }
  .phone-link a {
    color: rgb(41, 41, 41);
  }
}

@media (min-width: 1025px) {
  .section {
    width: 50%;
    padding: 0 0.5rem;
    margin: 0.5rem;
    overflow: auto;
  }
  .section a:hover,
  .section a:active {
    color: rgb(255, 255, 255);
    transition: all 200ms ease-in-out;
  }
  .phone-link a {
    margin-left: 0.5rem;
  }
}
