.header {
  display: flex;
  width: 100%;
  background-color: rgba(179, 179, 179, 0.5);
  backdrop-filter: blur(3px) saturate(120%) contrast(200%);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
  z-index: 10;
}

@media (prefers-color-scheme: dark) {
  .header {
    background-color: rgba(179, 179, 179, 0.5);
  }
}

@media (min-width: 1025px) {
  .header {
    position: absolute;
    top: 5%;
    width: 90%;
    left: 5%;
  }
}
