.list-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.list-item a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .list-item a {
    color: rgb(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  .list-item a:hover,
  .list-item a:active {
    color: rgb(255, 255, 255);
    transition: all 200ms ease-in-out;
  }
}
