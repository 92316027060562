.card {
  padding: 1rem;
  background-color: rgba(179, 179, 179, 0.92);
  backdrop-filter: blur(5px) saturate(120%) contrast(140%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
}

.content:hover::-webkit-scrollbar-thumb,
.content > *:hover::-webkit-scrollbar-thumb {
  background: #333;
}

@media (prefers-color-scheme: dark) {
  .card {
    background-color: rgba(179, 179, 179, 0.92);
  }
}
