@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/src/assets/20200128_162952.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 30% 10%;
}

#overlays {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
  border-radius: 22px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #333; */
  background: transparent;
  border-radius: 22px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

@media (min-width: 1025px) {
  body {
    background-size: calc(max(100%, 1486px));
    background-position: 0% 15%;
  }

  ::-webkit-scrollbar {
    display: block;
  }
}
