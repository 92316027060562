.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 20;
}

.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  z-index: 30;
  animation: slide-right 0.4s ease-out forwards;
}

.mobile-navigation ul {
  width: 90%;
  height: 100%;
  margin: 10% auto;
  padding: 0;
  text-align: center;
  list-style: none;
}

.mobile-navigation li {
  margin: 1rem 0;
}

.mobile-navigation a {
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 0.2rem 0;
  user-select: none;
}

.close {
  position: absolute;
  top: 1%;
  right: 2%;
}

.close button {
  vertical-align: middle;
  border: none;
  color: inherit;
  border-radius: 50%;
  background: transparent;
  position: relative;
  width: 32px;
  height: 32px;
  opacity: 0.6;
}
.close button:focus,
.close button:hover {
  opacity: 1;
  background: rgba(128, 128, 128, 0.5);
}
.close button:active {
  background: rgba(128, 128, 128, 0.9);
}
/* tines of the X */
.close button::before,
.close button::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  width: 4px;
  background-color: currentColor;
}
.close button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.close button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  33% {
    opacity: 0.33;
    transform: translateX(-33%);
  }
  66% {
    opacity: 0.66;
    transform: translateX(0);
    width: 85%;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    width: 80%;
  }
}
