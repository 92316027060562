.card {
  margin: 0;
  background-color: rgba(179, 179, 179, 0.5);
  text-align: center;
  position: absolute;
  top: 30vh;
  left: 5%;
  width: 90%;
}

@media (prefers-color-scheme: dark) {
  .card {
    background-color: rgba(179, 179, 179, 0.5);
  }
}

@media (min-width: 1025px) {
  .card {
    width: 45rem;
    left: calc(50% - 22.5rem);
  }
}
